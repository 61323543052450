import React from "react"

import LayoutSecondary from "../../components/layout-secondary";
import Seo from "../../components/seo"
import {injectIntl} from "gatsby-plugin-react-intl"
import HeroResources from "../../components/heroes/hero-resources";
import CaseStudiesData from "../../content/case-studies/index.json";
import {Col, Container, Row} from "react-bootstrap";
import SimpleCTA from "../../components/ctas/simple-cta";
import SimpleCard from "../../components/misc/simple-card";

const CaseStudiesIndexPage = ({ intl, location }) => (
    <LayoutSecondary>
        <Seo lang={intl.locale}
             title={CaseStudiesData[intl.locale].title}
             description={CaseStudiesData[intl.locale].text}
             pathname={location.pathname}
        />
        <HeroResources
            theme={`secondary`}
            content={CaseStudiesData[intl.locale]}
        />
        <div className={`top-main-overflow-wrapper`}>
            <Container>
                <Row>
                    {CaseStudiesData[intl.locale].cases.map((item, i) => {

                        if (item.cta) {

                            if (item.type === "simple-cta") {
                                return (
                                    <Col key={i} md={12}>
                                        <div style={{marginBottom: `40px`}}>
                                            <SimpleCTA theme={item.theme} content={item} />
                                        </div>
                                    </Col>
                                )
                            } else {
                                return null;
                            }

                        } else {
                            return (
                                <Col key={i} md={4}>
                                    <SimpleCard content={item} />
                                </Col>
                            )
                        }
                    })}
                </Row>
            </Container>
        </div>
    </LayoutSecondary>
)

export default injectIntl(CaseStudiesIndexPage)
